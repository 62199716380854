<template>
  <div class="widgetContainer cardDetails bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('details_title') }}
        </p>
        <span class="el-icon-close" @click="done" />
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <div class="cardDetails__warning" v-if="false">
        <span class="icon-exclamation" />
        <p>Proceeding will result in this card being deactivated immediately. A replacement card should arrive within a week.</p>
      </div>
      
      <h2 class="header-2">
        {{ $t('dashboard_cell_details') }}
      </h2>
      <div class="cardDetails__list">
        <ul>
          <li>
            <div class="details">
              <p class="label">
                {{ $t('enum_TransferType_card') }} : {{ getSelectedCard.label }}
              </p>
            </div>
            <div class="action" @click="spendLimitEdit">
              <span class="details">{{ $t('Edit') }}</span>
            </div>
          </li>
          <li>
            <div class="details">
              <p class="label">
                {{ $t('card_spendLimit') }} : <span>{{ spendLimit | currency }} {{ limitInterval }}</span>
              </p>
            </div>
            <div class="action" @click="spendLimitEdit">
              <span class="details">{{ $t('Edit') }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    limitAmount() {
      return this.getSelectedCard.limitAmount;
    },
    limitInterval() {
      return this.getSelectedCard.limitInterval;
    },
    spendLimit() {
      return this.limitAmount;
    }
  },
  methods: {
    cancel() {
      this.drawerPush('CardManagement');
    },
    done() {
      this.drawerPush('CardManagement');
    },
    labelEdit() {
      this.drawerPush('CardLabelForm');
    },
    spendLimitEdit() {
      this.drawerPush('CardSpendingForm');
    }
  }
};
</script>

<style lang="scss">
.cardDetails {

  &__warning {
    border: 2px solid #EDEFF1;
    border-radius: var(--radius);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    span {
      font-size: 30px;
      position: relative;

      &::before {
        z-index: 1;
        position: relative;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 70%;
        height: 70%;
        z-index: 0;
        background-color: #000;
      }
    }

    p {
      font-size: 14px;
      padding-left: 16px;
    }
  }

  &__list {
    ul {
      background: white;
      padding: 0 10px;
      border-radius: var(--radius);
      margin-bottom: 30px;
      border: 1px solid #edeff1;

      li {
        display: flex;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #eeeeee;
        &:last-child {
          border: unset;
        }

        .icon {
          background-color: var(--branding-primary);
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font-weight: 500;
          width: 40px;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
          }
        }

        .details {
          margin: 0 auto 0 15px;
          font-weight: 500;

          .label {
            font-size: 15px;
            line-height: 20px;

            span {
              color: var(--color-green);
            }
          }

          .value {
            color: rgba(#3c3c43, 0.6);
            font-size: 12px;
            padding-top: 2px;

          }
        }

        .action {
          cursor: pointer;

          &:hover {
            .icon-arrow-right {
              color: #000;
            }
          }

          .icon-arrow-right {
            padding: 10px;
            color: #c7c7c9;
            transition: 0.3s;
          }

          .details {
            border-radius: 20px;
            color: var(--branding-primary);
            text-decoration: underline;
            font-size: 13px;
            padding: 7px 9px;
            text-transform: uppercase;
            text-align: center;
            min-width: 80px;
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
